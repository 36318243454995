import {EntityModel} from "@/shared/model";
import type {DateTime} from "@/shared/date_time";

export class UserModel extends EntityModel {
    public readonly role: string
    public readonly type: string
    public readonly loginAt: DateTime
    public readonly lastName: string
    public readonly fullName: string
    public readonly firstName: string
    public readonly phoneNumber: string
    public readonly emailAddress: string

    constructor(data: object | null | undefined) {
        super(data)
        this.role = this.getString('role', 'unknown')
        this.type = this.getString('type', 'unknown')
        this.loginAt = this.getDateTime('login_at')
        this.lastName = this.getString('last_name', 'Unknown')
        this.firstName = this.getString('first_name', 'Unknown')
        this.fullName = `${this.firstName} ${this.lastName}`.trim()
        this.phoneNumber = this.getString('phone_number', 'Unknown')
        this.emailAddress = this.getString('email_address', 'Unknown')
    }
}