import {Context} from "@/shared/context";

export class WindowHelper {
    private ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }

    public scrollTop(): void {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
}