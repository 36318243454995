import {type IServiceHttpResponse, Service} from "@/shared/service";
import {ProjectModel} from "@/shared/models/project_model";

export class ProjectsService extends Service {

    async create(body: any): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects`)
        return this.privateApi.post(endpoint, body, true).then((response) => {
            const model = new ProjectModel(response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getAll(params: IGetAllProjectsParams): Promise<IProjectsResult> {
        const endpoint = this.endpoint('/projects', this.params(params))
        return this.privateApi.get(endpoint).then((response) => {
            const models: ProjectModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['projects'])) {
                response.data['projects'].forEach((data: any) => {
                    models.push(new ProjectModel(data))
                })
            }
            return {
                status: status,
                message: message,
                projects: models,
                success: success,
                pagination: pagination
            }
        })
    }

    public async getById(id: number): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new ProjectModel(response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getByUsername(username: string): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${username}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new ProjectModel(response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async updateById(id: number, body: any): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.put(endpoint, body, true).then((response) => {
            const model = new ProjectModel(response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    async deleteByID(id: number): Promise<IDeleteIProjectResult> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.delete(endpoint, true).then((response) => {
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                status: status,
                message: message,
                success: success
            }
        })
    }
}

interface IProjectResult extends IServiceHttpResponse {
    project: ProjectModel
}

export interface IGetAllProjectsParams {
    page: number
}

interface IProjectsResult extends IServiceHttpResponse {
    projects: ProjectModel[]
}

interface IDeleteIProjectResult extends IServiceHttpResponse {
}