<script setup lang="ts">
import {useApp} from "@/shared/app";
import {computed, onMounted, ref} from "vue";
import {onBeforeRouteUpdate} from "vue-router";

const app = useApp()

const page = ref<number>(1)

const projects = computed(() => app.stores.projects.get())

const getProjects = async () => {
  try {
    const result = await app.services.projects.getAll({
      page: page.value
    })
    if (result.success) {
      app.stores.projects.set(result.projects)
    }
  } catch (ex) {

  }
}

onMounted(async () => {
  await getProjects()
})

onBeforeRouteUpdate(async (newRoute, prevRoute) => {
  if (newRoute.name == 'projects' && prevRoute.name === 'projects:add') {
    await getProjects()
  }
})

</script>

<template>
  <div class="card shadow-sm">
    <div class="card-header border-bottom">
      <router-link :to="{name:'projects:add'}" class="btn btn-outline-primary">
        <i class="ti ti-plus me-1"></i>
        <span>Add Project</span>
      </router-link>
    </div>
    <div class="card-body text-center">
      <table>
        <thead>
        <tr>
          <th>Title</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="project in projects">
          <td>{{ project.title }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <router-view/>
</template>