import {Context} from "@/shared/context";

export class Config {
    public readonly dev: boolean
    public readonly prod: boolean
    public readonly backendServerBaseURL: string
    public readonly paginationLimit: number
    public readonly paginationOrder: string
    public readonly pagesTitlePrefix: string = 'Boldabode'
    public readonly adminTitlePrefix: string = 'Boldabode Admin'
    public readonly portalTitlePrefix: string = 'Boldabode Portal'

    constructor(ctx: Context) {
        const env = import.meta.env
        this.dev = env.DEV
        this.prod = env.PROD
        this.paginationLimit = 50
        this.paginationOrder = 'id.desc'
        this.backendServerBaseURL = this.dev ? 'http://localhost:8080' : 'https://boldabode.com'
    }
}