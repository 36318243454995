import {Config} from "@/shared/config";
import {Stores} from "@/shared/stores";
import {Logger} from "@/shared/logger";
import {Storage} from "@/shared/storage";
import {Helpers} from "@/shared/helpers";
import {Services} from "@/shared/services";
import {Messages} from "@/shared/messages";

export class Context {
    public readonly config: Config
    public readonly stores: Stores
    public readonly logger: Logger
    public readonly storage: Storage
    public readonly helpers: Helpers
    public readonly services: Services
    public readonly messages: Messages

    constructor() {
        this.config = new Config(this)
        this.stores = new Stores(this)
        this.logger = new Logger(this)
        this.storage = new Storage(this)
        this.helpers = new Helpers(this)
        this.services = new Services(this)
        this.messages = new Messages(this)
    }
}