import {EntityModel} from "@/shared/model";

export class FileModel extends EntityModel {
    public readonly url: string
    public readonly path: string

    constructor(data: object | null | undefined) {
        super(data)
        this.url = this.getString('url')
        this.path = this.getString('path')
    }
}
