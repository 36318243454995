import type {Context} from "@/shared/context";
import Typed from 'typed.js';
import {CountUp} from "countup.js";

export class DocumentHelper {
    private ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }

    public setTitle(prefix: string, suffix: any) {
        document.title = suffix ? `${prefix} - ${suffix}` : prefix
    }

    public initTyped(element: HTMLElement | null) {
        if (element) {
            try {
                const data = JSON.parse(element.getAttribute('data-typed-strings') as string)
                new Typed(element, {
                    loop: true,
                    strings: data,
                    typeSpeed: 40,
                    backSpeed: 40,
                    backDelay: 1e3
                })
            } catch (ex) {
                this.ctx.logger.error(ex)
            }
        }
    }

    initCountUp(element: HTMLElement | null) {
        if (element) {
            console.log(element)
            const countUp = new CountUp(element, 989, {enableScrollSpy: true})
        }
    }
}