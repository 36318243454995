<script setup lang="ts">
import {useApp} from "@/shared/app";
import {onMounted, ref, toRef, watch} from 'vue'
import Dropzone from 'dropzone'
import {ErrorMessage as VeeErrorMessage, Field as VeeField} from 'vee-validate'


const props = defineProps<{
  name: string
  hint?: string
  rules?: string
  label: string
  disabled?: boolean
}>()

const app = useApp()

const model = ref<string>('')
const dropzone = ref<Dropzone | null>(null)
const container = ref<HTMLElement | null>(null)
const uploadStatus = ref<string | null>(null)

const name = toRef(props, 'name')
const hint = toRef(props, 'hint')
const label = toRef(props, 'label')
const rules = toRef(props, 'rules')
const wrapperClass = `dropzone-${name.value.replace('_', '-')}`

const loadDropzone = () => {
  if (container.value) {
    dropzone.value = new Dropzone(container.value, {
      url: `${app.config.backendServerBaseURL}/app/v1/files/upload`,
      maxFiles: 1,
      acceptedFiles: "image/jpeg,image/png",
      addRemoveLinks: false,
      dictDefaultMessage: label.value,
      dictInvalidFileType: "Only JPEG and PNG files are allowed.",
    })

    dropzone.value.on("addedfile", (file) => {
      uploadStatus.value = null
      const elements = document.querySelectorAll(`.${wrapperClass} .dz-message`)
      if (elements) {
        elements.forEach((element) => {
          element.classList.add('d-none')
        })
      }
    })

    dropzone.value.on("error", (file, error: string) => {
      uploadStatus.value = 'error'
      app.logger.error(error)
    })

    dropzone.value.on("success", (file, response: any) => {
      if (!response) {
        uploadStatus.value = 'error'
        return
      }
      if (!response['code']) {
        uploadStatus.value = 'error'
        return
      }
      if (!response['data']) {
        uploadStatus.value = 'error'
        return
      }
      if (!response['data']['file']) {
        uploadStatus.value = 'error'
        return
      }
      model.value = response['data']['file'] as string
      uploadStatus.value = response['code'] == 'success' ? 'success' : 'error'
    })

    dropzone.value.on('maxfilesexceeded', (file: any) => {
      if (dropzone.value) {
        dropzone.value.removeAllFiles()
        dropzone.value.addFile(file)
      }
    })
  }
}

watch(model, () => {
  if (dropzone.value && model.value.length == 0) {
    dropzone.value.removeAllFiles()
  }
})

watch(container, () => {
  loadDropzone()
})

onMounted(async () => {


})

Dropzone.autoDiscover = false
</script>

<template>
  <small v-if="hint" class="text-muted d-block">{{ hint }}</small>
  <vee-field v-slot="{ field, meta}" :name="name" :rules="rules" v-model="model">
    <div ref="container" class="dropzone"
         :class="`${wrapperClass} ${meta.validated && !meta.valid?'is-invalid':''}`"></div>
    <input v-bind="field" type="hidden" class="d-none"/>
  </vee-field>
  <vee-error-message v-slot="{ message }" :name="name">
    <p class="invalid-feedback d-block p-0 m-0">{{ app.helpers.veeValidate.cleanErrorMessage(message) }}</p>
  </vee-error-message>
</template>