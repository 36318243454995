import axios from "axios";
import axiosRetry from "axios-retry";
import type {Context} from "@/shared/context";
import {PaginationModel} from "@/shared/models/pagination_model";
import type {Stores} from "@/shared/stores";

class Api {
    private readonly ctx: Context;
    private readonly baseUrl: string;

    constructor(ctx: Context, baseUrl: string) {
        this.ctx = ctx
        this.baseUrl = baseUrl
        axiosRetry(axios, {
            retries: 5,
            retryDelay: (retryCount) => {
                if (retryCount < 5) {
                    return retryCount * 2000
                }
                return 5000
            },
            retryCondition: (error) => {
                if (!error.response || !error.response.status) {
                    return true
                }
                return error.response.status === 503
            },
        })
    }

    public async get(endpoint: string, auth: boolean = false): Promise<IApiResponse> {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`
        }
        const url = `${this.baseUrl}${endpoint}`
        return axios({
            url: url,
            method: 'get',
            headers: this.getHeaders(auth),
            validateStatus: () => true
        }).then((response: any) => {
            return this.processResponse(response)
        })
    }

    public async put(endpoint: string, body: any, auth: boolean = false): Promise<IApiResponse> {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`
        }
        const url = `${this.baseUrl}${endpoint}`
        return axios({
            url: url,
            data: JSON.stringify(body),
            method: 'put',
            headers: this.getHeaders(auth),
            validateStatus: () => true
        }).then((response: any) => {
            return this.processResponse(response)
        })
    }

    public async post(endpoint: string, body: any, auth: boolean = false): Promise<IApiResponse> {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`
        }
        const url = `${this.baseUrl}${endpoint}`
        const json = JSON.stringify(body)
        return axios({
            url: url,
            data: json,
            method: 'post',
            headers: this.getHeaders(auth),
            validateStatus: () => true
        }).then((response: any) => {
            return this.processResponse(response)
        })
    }

    public async upload(endpoint: string, body: any, auth: boolean = false): Promise<IApiResponse> {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`
        }
        const url = `${this.baseUrl}${endpoint}`
        const json = JSON.stringify(body)
        const headers: any = this.getHeaders(auth)
        headers['Content-Type'] = 'multipart/form-data'
        return axios({
            url: url,
            data: json,
            method: 'post',
            headers: headers,
            validateStatus: () => true
        }).then((response: any) => {
            return this.processResponse(response)
        })
    }

    public async delete(endpoint: string, auth: boolean = false): Promise<IApiResponse> {
        if (!endpoint.startsWith('/')) {
            endpoint = `/${endpoint}`
        }
        const url = `${this.baseUrl}${endpoint}`
        return axios({
            url: url,
            method: 'delete',
            headers: this.getHeaders(auth),
            validateStatus: () => true
        }).then((response: any) => {
            return this.processResponse(response)
        })
    }

    private getHeaders(auth: boolean): any {
        const headers: any = {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
        }
        if (auth) {
            const token = this.ctx.storage.authToken.get()
            if (token) {
                headers["Authorization"] = `Bearer ${token}`
            }
        }
        return headers
    }

    private processResponse(response: any): IApiResponse {
        const body = response.data ? response.data : {}
        const status = response.status ? response.status : 0
        const authHeader = response.headers.get('Authorization')

        if (status == 401) {
            if (this.ctx.storage.authToken.exists()) {
                this.ctx.storage.authToken.delete()
            }
        }
        if (authHeader && authHeader.startsWith('Bearer ')) {
            const token = authHeader.replace('Bearer ', '').trim()
            this.ctx.storage.authToken.set(token)
        }
        let code = body.code ? body.code.trim() : 'error'
        let data = body.data ? body.data : {}
        let message = body.message ? body.message.trim() : ''
        const validation = body.validation ? body.validation : null
        const pagination = body.pagination ? new PaginationModel(body.pagination) : null
        if (code.length == 0) {
            code = status == 200 ? 'success' : 'error'
        }
        if (message.length == 0) {
            if (code == 'error' || code.toLowerCase().includes('error') || status != 200) {
                message = 'An unexpected error has occurred.'
            }
        }
        return {
            code: code,
            data: data,
            status: status,
            message: message,
            pagination: pagination,
            validation: validation,
        }
    }
}

export class Service {
    protected readonly ctx: Context
    protected readonly stores: Stores
    protected readonly publicApi: Api
    protected readonly privateApi: Api


    constructor(ctx: Context) {
        this.ctx = ctx
        this.stores = this.ctx.stores
        this.publicApi = new Api(ctx, `${ctx.config.backendServerBaseURL}/api/v1`)
        this.privateApi = new Api(ctx, `${ctx.config.backendServerBaseURL}/app/v1`)
    }

    protected params(params: any): any {
        if (!params.order || params.order.length == 0) {
            params.order = this.ctx.config.paginationOrder
        }
        if (!params.limit || params.limit <= 0) {
            params.limit = this.ctx.config.paginationLimit
        }
        return params
    }

    protected endpoint(endpoint: string, params?: any): string {
        if (!params) {
            return endpoint
        }
        const queryParams = Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&')
        return `${endpoint}?${queryParams}`
    }
}

export interface IApiResponse {
    code: string
    data: any | Object | any[]
    status: number
    message: string
    pagination?: null | PaginationModel
    validation?: null | Record<string, IValidationMessage[]>
}

export interface IValidationMessage {
    tag: string,
    type: string
    param: null | string,
    message: string
}

export interface IServiceHttpResponse {
    status: number
    success: boolean
    message: string
    pagination?: null | PaginationModel
    validation?: null | Record<string, IValidationMessage[]>
}