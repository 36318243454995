import {Context} from "@/shared/context";
import {type IProjectsStore, useProjectsStore} from "@/shared/stores/projects_store";
import {type IPostsStore, usePostsStore} from "@/shared/stores/posts_store";
import {type IMeStore, useMeStore} from "@/shared/stores/me_store";

export class Stores {
    public readonly me: IMeStore
    public readonly posts: IPostsStore
    public readonly projects: IProjectsStore

    constructor(ctx: Context) {
        this.me = useMeStore()
        this.posts = usePostsStore()
        this.projects = useProjectsStore()
    }
}