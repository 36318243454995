import {ref} from 'vue'
import {defineStore} from 'pinia'
import {PostModel} from "@/shared/models/post_model";

export interface IPostsStore {
    get(): PostModel[]

    set(value: PostModel | PostModel[]): void

    getById(id: number): PostModel | null

    deleteById(id: number): void

    deleteAll(): void
}

export const usePostsStore = defineStore('posts', (): IPostsStore => {
    const data = ref<PostModel[]>([])

    const get = (): PostModel[] => {
        return data.value as PostModel[]
    }

    const set = (value: PostModel | PostModel[]): void => {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                const index = data.value.findIndex(value => value.id === item.id)
                if (index < 0) {
                    data.value.push(item)
                } else {
                    data.value[index] = item
                }
            })
        } else {
            const item = value
            const index = data.value.findIndex(value => value.id === item.id)
            if (index < 0) {
                data.value.push(value)
            } else {
                data.value[index] = value
            }
        }
        data.value = data.value.sort((a, b) => b.id - a.id)
    }

    const getById = (id: number): PostModel | null => {
        const index = data.value.findIndex(value => value.id === id)
        if (index >= 0) {
            return data.value[index] as PostModel
        }
        return null
    }

    const deleteById = (id: number): void => {
        const index = data.value.findIndex(value => value.id === id)
        if (index >= 0) {
            data.value.splice(index, 1)
        }
    }

    const deleteAll = (): void => {
        data.value = []
    }

    return {set, get, getById, deleteById, deleteAll}
})