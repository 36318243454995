import {Context} from "@/shared/context";

export class VeeValidateHelper {
    private ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }
    
    public cleanErrorMessage(message?: string): string | undefined {
        if (message) {
            message = message.trim()
            message = message.split('_').join(' ')
            if (message.length > 0) {
                return message.charAt(0).toUpperCase() + message.slice(1)
            }
        }
        return message
    }
}