import "bootstrap"
import "dropzone/dist/dropzone.css"
import "@/assets/css/app/app.css"
import "@/assets/css/app/app-custom.css"

import {createApp} from "vue"
import {createPinia} from "pinia"
import App from "./App.vue"
import router from "./router"

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.mount('#app')