import {Context} from "@/shared/context";
import {ProjectsService} from "@/shared/services/projects_service";
import {MeService} from "@/shared/services/me_service";
import {AuthService} from "@/shared/services/auth_service";

export class Services {
    public readonly me: MeService
    public readonly auth: AuthService
    public readonly projects: ProjectsService

    constructor(ctx: Context) {
        this.me = new MeService(ctx)
        this.auth = new AuthService(ctx)
        this.projects = new ProjectsService(ctx)
    }
}