<script setup lang="ts">
import {useApp} from "@/shared/app";
import {onMounted, ref, toRef} from 'vue'
import {ErrorMessage as VeeErrorMessage, Field as VeeField} from 'vee-validate'


const props = defineProps<{
  name: string
  type: string
  hint?: string
  rules?: string
  label: string
  disabled: boolean
  placeholder?: string
}>()

const app = useApp()

const model = ref<string>('')

const name = toRef(props, 'name')
const hint = toRef(props, 'hint')
const label = toRef(props, 'label')
const rules = toRef(props, 'rules')
const placeholder = toRef(props, 'placeholder')

onMounted(async () => {
})

</script>

<template>
  <small v-if="hint" class="text-muted d-block">{{ hint }}</small>
  <vee-field v-slot="{ field, meta}" :name="name" :rules="rules" v-model="model">
    <div class="form-floating">
      <input v-bind="field"
             :type="type"
             :disabled="disabled"
             :placeholder="placeholder"
             class="form-control"
             :class="{'is-invalid':meta.validated && !meta.valid}">
      <label>{{ label }}</label>
    </div>
  </vee-field>
  <vee-error-message v-slot="{ message }" :name="name">
    <p class="invalid-feedback d-block p-0 m-0">{{ app.helpers.veeValidate.cleanErrorMessage(message) }}</p>
  </vee-error-message>
</template>