<script setup lang="ts">
import {onMounted, ref, toRef} from 'vue'
import {useApp} from "@/shared/app";
import {useRoute} from 'vue-router'
import {EMessageType, type IMessage} from "@/shared/types";
import Spinner from "@/shared/comp/Spinner/Spinner.vue";
import VueForm, {type VueFormActions} from "@/shared/comp/VueForm/VueForm.vue";
import VueFormInput from "@/shared/comp/VueForm/VueFormInput.vue";

const props = defineProps<{
  redirectUrl: string
}>()

const app = useApp()
const route = useRoute()

const message = ref<IMessage | null>(null)
const isSubmitting = ref<boolean>(false)
const validationSchema = ref<any>({
  username: 'required|email',
  password: 'required'
})

const redirectUrl = toRef(props, 'redirectUrl', '/')

const handleSubmit = async (values: any, actions: VueFormActions) => {
  try {
    isSubmitting.value = true
    const body = {
      username: values['username'],
      password: values['password']
    }
    const result = await app.services.auth.login(body)
    await app.helpers.async.sleep(2000)
    message.value = {
      type: result.success ? EMessageType.Success : EMessageType.Danger,
      body: result.message
    }
    isSubmitting.value = false
    if (result.success) {
      actions.resetForm()
      await app.helpers.async.sleep(3000)
      window.location.href = redirectUrl.value
    }
  } catch (ex) {
    app.logger.error(ex)
    message.value = {
      type: EMessageType.Danger,
      body: app.messages.fromException(ex)
    }
    isSubmitting.value = false
  }
}

onMounted(async () => {
})
</script>

<template>

  <div class="position-relative min-vh-100 w-100 d-flex justify-content-center bg-light">
    <div class="auth-card">
      <div class="card shadow mx-3 mx-md-auto">
        <div class="card-body p-0 position-relative">
          <spinner v-if="isSubmitting" :overlay="true"/>
          <div class="px-4">
            <a href="/" class="text-nowrap logo-img text-center d-block mb-5 w-100">
              <img src="/assets/img/app/app-logo.png" class="logo dark-logo" alt="Boldabode">
            </a>
            <div v-if="message" :class="`alert alert-${message.type} mb-4`">
              {{ message.body }}
            </div>
            <div v-else class="alert alert-light text-dark fs-3">
              <template v-if="route.query.r || route.query.redirect">
                Please sign in first to continue
              </template>
              <template v-else>
                Sign in to Boldabode
              </template>
            </div>
            <vue-form @submit="handleSubmit"
                      :validation-schema="validationSchema">
              <div class="mb-3">
                <vue-form-input type="text"
                                name="username"
                                label="Username"
                                placeholder="Enter username"
                                :disabled="isSubmitting"/>
              </div>
              <div class="mb-1">
                <vue-form-input type="password"
                                name="password"
                                label="Password"
                                placeholder="Enter password"
                                :disabled="isSubmitting"/>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-4">
                <router-link :to="{name:'auth:login'}" class="text-primary fw-medium">
                  Forgot Password?
                </router-link>
              </div>
              <button :disabled="isSubmitting" type="submit" class="btn btn-primary w-100 mb-4 rounded-2 fs-4"
                      style="height: 50px">
                Sign In
              </button>
              <!--
              <div class="text-center w-100 d-none">
                New here?
                <router-link :to="{name:'register', query:route.query}" class="fw-medium ms-2">
                  Register account
                </router-link>
              </div>
              -->
            </vue-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>