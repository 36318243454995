<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import {type RouteRecordRaw, useRoute, useRouter} from "vue-router";
import {useApp} from "@/shared/app";
import {EMessageType, type IMessage} from "@/shared/types";
import Spinner from "@/shared/comp/Spinner/Spinner.vue";

const app = useApp()
const route = useRoute()
const router = useRouter()

const message = ref<IMessage | null>(null)
const isLoading = ref<boolean>(true)
const breadcrumb = ref<any[]>([])
const routeName = ref<string | null>(route.name as string)
const showBreadcrumb = ref<boolean>(true)

const me = computed(() => app.stores.me.get())

const setup = async (): Promise<boolean> => {
  try {
    const success = await app.setup()
    if (!success) {
      message.value = {
        type: EMessageType.Danger,
        body: app.messages.unexpectedError,
      }
    }
    return success
  } catch (ex) {
    message.value = {
      type: EMessageType.Danger,
      body: app.messages.fromException(ex),
    }
    app.logger.error(ex)
  }
  return false
}

const getMe = async (): Promise<boolean> => {
  try {
    const result = await app.services.me.get()
    if (result.success) {
      app.stores.me.set(result.user)
    } else {
      if (result.status == 401) {
        loginRedirect()
      }
    }
  } catch (ex) {
    message.value = {
      type: EMessageType.Danger,
      body: app.messages.fromException(ex),
    }
    app.logger.error(ex)
  }
  return false
}

const getSetup = async (): Promise<boolean> => {
  return true
}

const loginRedirect = () => {
  const currentRouteName = route.name as string
  if (currentRouteName && !currentRouteName.startsWith('auth:')) {
    window.location.href = '/admin/login'
  }
}

const findRouteByName = (name: string): RouteRecordRaw | undefined => {
  return router.options.routes.find(r => r.name === name)
}

const toggleNavbarCollapse = () => {
  const body = document.getElementsByTagName('body')[0]
  const wrapper = document.getElementById('main-wrapper')
  if (body && wrapper) {
    if (window.innerWidth <= 1300) {
      if (!wrapper.classList.contains('show-sidebar')) {
        wrapper.classList.add('show-sidebar')
      } else {
        wrapper.classList.remove('show-sidebar')
      }
      return
    }
    if (body.getAttribute('data-sidebartype') === 'full') {
      body.setAttribute('data-sidebartype', 'mini')
    } else {
      body.setAttribute('data-sidebartype', 'full')
    }
  }
}

watch(route, () => {
  routeName.value = route.name as string
  app.helpers.window.scrollTop()
  app.helpers.document.setTitle(app.config.adminTitlePrefix, route.meta.title)
  if (!app.services.auth.isAuthenticated()) {
    loginRedirect()
  }
})

onMounted(async () => {
  isLoading.value = true
  if (await setup()) {
    if (await getSetup()) {
      if (app.services.auth.isAuthenticated()) {
        await getMe()
      }
    }
  }
  await app.helpers.async.sleep(2000)
  isLoading.value = false
})
</script>

<template>
  <div>
    <template v-if="isLoading">
      <div class="container position-relative" style="height: 100vh">
        <spinner/>
      </div>
    </template>
    <template v-if="!isLoading && routeName">
      <template v-if="me && me.id > 0">
        <div id="main-wrapper">
          <aside class="left-sidebar with-vertical">
            <div>
              <div class="brand-logo d-flex align-items-center justify-content-between">
                <a class="text-nowrap" href="/">
                  <div class="logo-img ps-2">
                    <img alt="Logo-Dark" class="dark-logo" src="/assets/img/app/app-logo.png" style="height: 40px">
                    <img alt="Logo-light" class="light-logo" src="/assets/img/app/app-logo-white.png"
                         style="display: none;">
                  </div>
                </a>
                <button
                    class="bg-transparent border-0 sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none"
                    @click="toggleNavbarCollapse">
                  <i class="ti ti-x"></i>
                </button>
              </div>
              <nav class="sidebar-nav scroll-sidebar" data-simplebar>
                <ul class="mt-4">
                  <li class="sidebar-item mb-2">
                    <a class="sidebar-link" href="/" target="_blank">
                      <span>
                        <i class="ti ti-home"></i>
                      </span>
                      <span class="hide-menu">Home</span>
                    </a>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link :class="{'active' :  routeName?.startsWith('dashboard')}"
                                 :to="{name:'dashboard'}"
                                 class="sidebar-link">
                      <span>
                        <i class="ti ti-chart-bar"></i>
                      </span>
                      <span class="hide-menu">Dashboard</span>
                    </router-link>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link :class="{'active' :  routeName?.startsWith('account')}"
                                 :to="{name:'account'}"
                                 class="sidebar-link">
                      <span>
                        <i class="ti ti-user"></i>
                      </span>
                      <span class="hide-menu">Account</span>
                    </router-link>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link
                        :class="{'active' :  routeName.startsWith('posts')}"
                        :to="{name:'posts'}"
                        class="sidebar-link">
                      <span>
                        <i class="ti ti-files"></i>
                      </span>
                      <span class="hide-menu">Posts</span>
                    </router-link>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link
                        :class="{'active' :  routeName.startsWith('projects')}"
                        :to="{name:'projects'}"
                        class="sidebar-link">
                      <span>
                        <i class="ti ti-stack-3"></i>
                      </span>
                      <span class="hide-menu">Projects</span>
                    </router-link>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link
                        :class="{'active' :  routeName.startsWith('invoices')}"
                        :to="{name:'invoices'}"
                        class="sidebar-link">
                      <span>
                        <i class="ti ti-file"></i>
                      </span>
                      <span class="hide-menu">Invoices</span>
                    </router-link>
                  </li>
                  <li class="sidebar-item mb-2">
                    <router-link
                        :class="{'active' :  routeName.startsWith('contracts')}"
                        :to="{name:'contracts'}"
                        class="sidebar-link">
                      <span>
                        <i class="ti ti-folder"></i>
                      </span>
                      <span class="hide-menu">Contracts</span>
                    </router-link>
                  </li>
                </ul>
              </nav>
              <div class="card fixed-profile mb-2 bg-light-subtle border-1 shadow-none py-2 px-3 my-3 mx-3">
                <div class="h-stack gap-2">
                  <router-link :to="{name:'account'}" class="img">
                    <img alt="" class="rounded-circle" src="/assets/img/user/avatar-rounded.png" height="50"
                         width="50">
                  </router-link>
                  <router-link :to="{name:'account'}" class="title mt-2">
                    <h6 class="mb-0 fs-3 fw-semibold mb-0 overflow-hidden text-ellipsis">
                      {{ me.firstName }}
                    </h6>
                    <span class="fs-1 fw-semibold text-uppercase text-muted overflow-hidden text-ellipsis">
                      {{ me.type }} account
                    </span>
                  </router-link>
                  <a class="btn-link border-0 bg-transparent ms-auto text-primary" href="/logout">
                    <i class="ti ti-power fs-7"></i>
                  </a>
                </div>
              </div>
            </div>
          </aside>
          <div class="page-wrapper">
            <header class="topbar">
              <div class="with-vertical">
                <nav class="navbar navbar-expand-lg p-0">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <button class="nav-link sidebartoggler nav-icon-hover ms-n3" @click="toggleNavbarCollapse">
                        <i class="ti ti-menu-2"></i>
                      </button>
                    </li>
                    <li class="nav-item d-none d-lg-block">
                      <a class="nav-link nav-icon-hover" data-bs-target="#search-modal" data-bs-toggle="modal"
                         href="#">
                        <i class="ti ti-search"></i>
                      </a>
                    </li>
                  </ul>
                  <div class="navbar-collapse justify-content-end">
                    <div class="d-flex align-items-center justify-content-between">
                      <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                        <li class="nav-item dropdown">
                          <button aria-expanded="false" class="nav-link nav-icon-hover" data-bs-toggle="dropdown">
                            <i class="ti ti-bell fs-7"></i>
                          </button>
                          <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up">
                            <div class="py-7 px-6">
                              <div class="text-center w-80 text-muted">
                                <i class="ti ti-info-circle fs-8"></i>
                                <p class="fs-4 m-0 mx-5">
                                  There are no new notifications at the moment.
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item dropdown">
                          <div aria-expanded="true" class="nav-link cursor-pointer pe-0" data-bs-toggle="dropdown">
                            <div class="d-flex align-items-center">
                              <div class="user-profile-img">
                                <img alt="" class="rounded-circle" height="35"
                                     src="/assets/img/user/avatar-rounded.png"
                                     width="35">
                              </div>
                            </div>
                          </div>
                          <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                               data-bs-popper="static">
                            <div class="profile-dropdown position-relative" data-simplebar>
                              <router-link :to="{name:'account'}"
                                           class="d-flex align-items-center py-9 mx-0 px-7 border-bottom">
                                <img alt="" class="rounded-circle" height="65"
                                     src="/assets/img/user/avatar-rounded.png" width="65">
                                <div class="ms-3">
                                  <h5 class="mb-1 fs-3">{{ me.fullName }}</h5>
                                  <h5 class="mb-1 fs-1 text-uppercase">{{ me.type }} account</h5>
                                  <h5 class="mb-0 fs-2 align-items-center gap-2">
                                    {{ me.emailAddress }}
                                  </h5>
                                </div>
                              </router-link>
                              <div class="message-body">
                                <router-link :to="{name:'account'}"
                                             class="py-8 px-7 d-flex align-items-center border-bottom">
                                  <span
                                      class="d-flex align-items-center justify-content-center rounded-1 p-6 bg-primary-subtle">
                                    <i class="ti ti-user fs-5"></i>
                                  </span>
                                  <div class="w-75 d-inline-block v-middle ps-3">
                                    <h6 class="mb-1 fs-3 fw-semibold lh-base">Account</h6>
                                    <h6 class="fs-2 d-block fs-2 fw-lighter mb-0">
                                      <span>Account</span>
                                      <span v-if="me.type === 'coach'"> and Coaches</span>
                                      <span v-if="me.type === 'athlete'"> and Athletes</span>
                                    </h6>
                                  </div>
                                </router-link>
                                <template v-if="me.type === 'athlete'">
                                </template>
                                <router-link :to="{name:'account:security'}"
                                             class="py-8 px-7 d-flex align-items-center border-bottom">
                                  <span
                                      class="d-flex align-items-center justify-content-center rounded-1 p-6 bg-primary-subtle">
                                    <i class="ti ti-lock fs-5"></i>
                                  </span>
                                  <div class="w-75 d-inline-block v-middle ps-3">
                                    <h6 class="mb-1 fs-3 fw-semibold lh-base">Account Security</h6>
                                    <h6 class="fs-2 d-block fs-2 fw-lighter mb-0">Password and Authentication</h6>
                                  </div>
                                </router-link>
                              </div>
                              <div class="d-grid py-4 px-7">
                                <a class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                                   href="/logout">
                                  <i class="ti ti-power fs-5 me-3"></i><span>Log Out</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </header>
            <div class="body-wrapper">
              <div class="container-fluid">
                <div v-if="showBreadcrumb"
                     class="card card-border bg-light-subtle shadow-none border-1 mb-4">
                  <div class="card-body px-4 py-3">
                    <div class="row align-items-center">
                      <div class="col-9">
                        <h5 class="fw-semibold mb-8">{{ route.meta.title }}</h5>
                        <nav aria-label="breadcrumb">
                          <ol class="breadcrumb">
                            <li v-for="item in breadcrumb" class="breadcrumb-item">
                              <router-link :to="{name:item}" class="text-decoration-none">
                                {{ findRouteByName(item)?.meta?.title }}
                              </router-link>
                            </li>
                            <li aria-current="page" class="breadcrumb-item text-muted">
                              {{ route.meta.title }}
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <router-view/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <router-view/>
      </template>
    </template>
  </div>
</template>