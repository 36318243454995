import {Context} from "@/shared/context";

export class Messages {
    public readonly unexpectedError: string
    public readonly connectionError: string

    constructor(ctx: Context) {
        this.unexpectedError = 'An unexpected error has occurred. Please try again later.'
        this.connectionError = 'An connection error has occurred. Please check your connection and try again.'
    }

    public fromException(ex: any): string {
        return this.unexpectedError
    }
}