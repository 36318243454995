export type IMessage = {
    type: EMessageType
    title?: string
    body: string
    footer?: string
}

export enum EMessageType {
    Info = 'info',
    Danger = 'danger',
    Success = 'success',
    Warning = 'warn',
    Progress = 'progress'
}

export enum EFormInputType {
    Text = 'text',
    Password = 'date',
    Number = 'number',
}