<script setup lang="ts">
import {useApp} from "@/shared/app";

const app = useApp()

</script>

<template>
  <div class="card shadow-sm">
    <div class="card-body text-center" style="padding: 80px 0">
      <i class="ti ti-info-circle fs-8 text-muted"></i>
      <h5 class="text-muted mt-3">Coming soon</h5>
    </div>
  </div>
</template>