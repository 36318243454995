<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import AuthLogin from "@/shared/comp/Auth/AuthLogin.vue";

const route = useRoute()

const redirectUrl = ref<string>('/admin')

onMounted(() => {
  if (route.query.r && route.query.r.length > 0) {
    redirectUrl.value = route.query.r as string
  }
  if (route.query.redirect && route.query.redirect.length > 0) {
    redirectUrl.value = route.query.redirect as string
  }
})
</script>
<template>
  <div class="auth-login">
    <auth-login :redirect-url="redirectUrl"/>
  </div>
</template>