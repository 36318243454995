import {Context} from "@/shared/context";
import {AsyncHelper} from "@/shared/helpers/async_helper";
import {WindowHelper} from "@/shared/helpers/window_helper";
import {DocumentHelper} from "@/shared/helpers/document_helper";
import {VeeValidateHelper} from "@/shared/helpers/vee_validate";

export class Helpers {
    public readonly async: AsyncHelper
    public readonly window: WindowHelper
    public readonly document: DocumentHelper
    public readonly veeValidate: VeeValidateHelper

    constructor(ctx: Context) {
        this.async = new AsyncHelper(ctx)
        this.window = new WindowHelper(ctx)
        this.document = new DocumentHelper(ctx)
        this.veeValidate = new VeeValidateHelper(ctx)
    }
}