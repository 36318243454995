import {createRouter, createWebHistory} from 'vue-router'
import DashboardView from "../views/DashboardView.vue"
import LoginView from "@/apps/admin/views/LoginView.vue";
import ProjectsView from "@/apps/admin/views/ProjectsView.vue";
import AddProjectView from "@/apps/admin/views/AddProjectView.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/admin',
            name: 'dashboard',
            component: DashboardView,
            meta: {
                title: 'Dashboard'
            }
        },
        {
            path: '/admin/account',
            name: 'account',
            component: DashboardView,
            meta: {
                title: 'Account'
            }
        },
        {
            path: '/admin/account/security',
            name: 'account:security',
            component: DashboardView,
            meta: {
                title: 'Account Security'
            }
        },
        {
            path: '/admin/posts',
            name: 'posts',
            component: DashboardView,
            meta: {
                title: 'Posts'
            }
        },
        {
            path: '/admin/projects',
            name: 'projects',
            component: ProjectsView,
            meta: {
                title: 'Projects'
            },
            children: [
                {
                    path: '/admin/projects/add',
                    name: 'projects:add',
                    component: AddProjectView,
                    meta: {
                        title: 'Add Project'
                    },
                }
            ]
        },
        {
            path: '/admin/invoices',
            name: 'invoices',
            component: DashboardView,
            meta: {
                title: 'Invoices'
            }
        },
        {
            path: '/admin/contracts',
            name: 'contracts',
            component: DashboardView,
            meta: {
                title: 'Contacts'
            }
        },
        {
            path: '/admin/login',
            name: 'auth:login',
            component: LoginView,
            meta: {
                title: 'Login'
            }
        },
    ]
})

export default router
