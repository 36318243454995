import {ref} from 'vue'
import {defineStore} from 'pinia'
import type {UserModel} from "@/shared/models/user_model";

export interface IMeStore {
    get(): null | UserModel

    set(value: UserModel): void

    reset(): void
}

export const useMeStore = defineStore('me', (): IMeStore => {
    const data = ref<UserModel | null>(null)

    const get = (): null | UserModel => {
        return data.value as UserModel
    }

    const set = (value: UserModel): void => {
        data.value = value
    }

    const reset = () => {
        data.value = null
    }
    return {set, get, reset}
})