import Cookies from "js-cookie";
import type {Context} from "@/shared/context";

export class Storage {
    public readonly cookies: CookieStorage
    public readonly authToken: AuthTokenStorage

    constructor(ctx: Context) {
        this.cookies = new CookieStorage(ctx)
        this.authToken = new AuthTokenStorage(ctx)
    }
}

export class CookieStorage {
    private readonly ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }

    public get(name: string): string | null {
        const value = Cookies.get(name)
        if (!value) {
            return null
        }
        return value
    }

    public set(name: string, value: string, attributes: ICookieAttributes) {
        Cookies.set(name, value, {
            path: '/',
            expires: attributes.expires,
        })
    }

    public remove(name: string): void {
        Cookies.remove(name)
    }
}

class AuthTokenStorage {
    private readonly ctx: Context
    private readonly cookies: CookieStorage
    private readonly cookieName: string

    constructor(ctx: Context) {
        this.ctx = ctx
        this.cookies = new CookieStorage(ctx)
        this.cookieName = 'auth_token'
    }

    public set(token: string): boolean {
        let expiresAt = 30
        const decodedToken = this.decode(token)
        if (decodedToken != null) {
            if (decodedToken.expiresAt) {
                const date = new Date()
                const differenceMillis = decodedToken.expiresAt.getTime() - date.getTime()
                const differenceDays = differenceMillis / (1000 * 3600 * 24)
                if (differenceDays >= 1) {
                    expiresAt = differenceDays
                }
            }
        }
        this.cookies.set(this.cookieName, token.trim(), {
            expires: expiresAt,
            sameSite: 'lax',
        })
        return this.exists()
    }

    public get(): null | string {
        const token = this.cookies.get(this.cookieName)
        if (token == null) {
            return null
        }
        return token.trim()
    }

    public delete(): boolean {
        if (this.exists()) {
            this.cookies.remove(this.cookieName)
        }
        return this.get() == null
    }

    public exists(): boolean {
        return this.get() != null
    }

    private decode(token: string | null): IJWTToken | null {
        if (token == null) {
            return null
        }
        let val = token.split('.')[1]
        let data = {
            id: null,
            iat: null,
            exp: null
        }
        if (!val) {
            return null
        }
        try {
            data = JSON.parse(atob(val))
        } catch (ex) {
            this.ctx.logger.error(ex)
        }
        const id = data.id
        const exp = data.exp
        const iat = data.iat

        if (!exp || exp <= 0) {
            return null
        }
        if (!iat || iat <= 0) {
            return null
        }
        if (!id || id <= 0) {
            return null
        }
        return {
            id: id,
            issuedAt: new Date(iat * 1000),
            expiresAt: new Date(exp * 1000),
        }
    }

}

interface IJWTToken {
    id: number
    issuedAt: Date
    expiresAt: Date
}

interface ICookieAttributes {
    expires: number
    sameSite: string
}