import type {Context} from "@/shared/context";

export class AsyncHelper {
    private ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }

    async sleep(duration: number): Promise<void> {
        await new Promise(r => setTimeout(r, duration))
    }
}