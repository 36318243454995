<script setup lang="ts">
import {ref} from "vue";
import {useApp} from "@/shared/app";
import Offcanvas from "@/shared/comp/Offcanvas/Offcanvas.vue";
import {useRouter} from "vue-router";
import {EMessageType, type IMessage} from "@/shared/types";
import Spinner from "@/shared/comp/Spinner/Spinner.vue";
import VueForm, {type VueFormActions} from "@/shared/comp/VueForm/VueForm.vue";
import VueFormInput from "@/shared/comp/VueForm/VueFormInput.vue";
import VueFormTextareaInput from "@/shared/comp/VueForm/VueFormTextareaInput.vue";
import VueFormFileInput from "@/shared/comp/VueForm/VueFormFileInput.vue";
import VueFormDurationInput from "@/shared/comp/VueForm/VueFormDurationInput.vue";

const app = useApp()
const router = useRouter()

const message = ref<IMessage | null>(null)
const offcanvas = ref<typeof Offcanvas | null>(null)
const isSubmitting = ref<boolean>(false)
const validationSchema = ref<any>({
  title: 'required',
  subtitle: 'required',
  duration: 'required',
  description: 'required',
  after_image: 'required',
  before_image: 'required',
  client_name: 'required',
})

const handleHide = () => {
  router.push({name: 'projects'})
}

const handleSubmit = async (values: any, actions: VueFormActions) => {
  if (offcanvas.value) {
    offcanvas.value.scrollTop()
  }
  try {
    isSubmitting.value = true
    const body = {
      title: values['title'],
      subtitle: values['subtitle'],
      duration: values['duration'],
      description: values['description'],
      after_image: values['after_image'],
      before_image: values['before_image'],
      client_name: values['client_name'],
    }
    const result = await app.services.projects.create(body)
    await app.helpers.async.sleep(2000)
    message.value = {
      type: result.success ? EMessageType.Success : EMessageType.Danger,
      body: result.message
    }
    isSubmitting.value = false
    if (result.success) {
      actions.resetForm()
      app.stores.projects.set(result.project)
      await app.helpers.async.sleep(3000)
    }
  } catch (ex) {
    app.logger.error(ex)
    message.value = {
      type: EMessageType.Danger,
      body: app.messages.fromException(ex)
    }
    isSubmitting.value = false
  }
}
</script>

<template>
  <offcanvas ref="offcanvas" title="Add Project" :show="true" @hide="handleHide">
    <template #body>
      <spinner v-if="isSubmitting" :overlay="true"/>

      <div v-if="message" :class="`alert alert-${message.type} mb-4`">
        {{ message.body }}
      </div>
      <vue-form @submit="handleSubmit" :validation-schema="validationSchema">
        <div class="mb-3">
          <vue-form-input type="text"
                          name="title"
                          label="Title"
                          placeholder="Enter title"
                          :disabled="isSubmitting"/>
        </div>
        <div class="mb-3">
          <vue-form-textarea-input
              name="subtitle"
              label="Subtitle"
              placeholder="Enter subtitle"
              :disabled="isSubmitting"/>
        </div>
        <div class="mb-3">
          <vue-form-textarea-input name="description"
                                   label="Description"
                                   placeholder="Enter description"
                                   :disabled="isSubmitting"/>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <vue-form-file-input name="before_image"
                                 label="Drag & drop an before image or click to select one"
                                 :disabled="isSubmitting"/>
          </div>
          <div class="col-md-6">
            <vue-form-file-input name="after_image"
                                 label="Drag & drop an after image or click to select one"
                                 :disabled="isSubmitting"/>
          </div>
        </div>
        <div class="mb-3">
          <vue-form-duration-input type="text"
                                   name="duration"
                                   label="Duration"
                                   placeholder="Enter project duration"
                                   :disabled="isSubmitting"/>
        </div>
        <div class="mb-3">
          <vue-form-input type="text"
                          name="client_name"
                          label="Client name"
                          placeholder="Enter client name"
                          :disabled="isSubmitting"/>
        </div>
        <button :disabled="isSubmitting" type="submit" class="btn btn-primary mb-4 rounded-2 fs-3 px-5 mt-2">
          Submit
        </button>
      </vue-form>
    </template>
  </offcanvas>
</template>