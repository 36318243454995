<script setup lang="ts">
import { Form as VeeForm, type FormActions as VeeFormActions } from 'vee-validate'
import { toRef } from 'vue'

export type VueFormData = { [key: string]: any }

export type VueFormActions = VeeFormActions<any>

const emit = defineEmits<{
  (e: 'submit', data: VueFormData, actions: VueFormActions): void
}>()

const props = defineProps<{
  keepValues?: boolean
  validationSchema?: { [key: string]: any }
}>()

const keepValues = toRef(props, 'keepValues', false)
const validationSchema = toRef(props, 'validationSchema', {})

const handleSubmit = (data: VueFormData, actions: VueFormActions) => {
  emit('submit', data, actions)
}
</script>

<template>
  <vee-form @submit="handleSubmit" :keep-values="keepValues" :validation-schema="validationSchema">
    <slot></slot>
  </vee-form>
</template>

<style scoped>

</style>