import {type IServiceHttpResponse, Service} from "@/shared/service";
import {UserModel} from "@/shared/models/user_model";

export class MeService extends Service {

    public async get(): Promise<IGetAllProjectsResult> {
        const endpoint = this.endpoint('/me')
        return this.privateApi.get(endpoint, true).then((response) => {
            return {
                status: response.status,
                message: response.message,
                success: response.code == 'success',
                user: new UserModel(response.data['user'])
            }
        })
    }
}

interface IGetAllProjectsResult extends IServiceHttpResponse {
    user: UserModel
}