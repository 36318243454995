<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, toRef } from 'vue'
import { Offcanvas } from 'bootstrap'
import SimpleBar from 'simplebar'

const emit = defineEmits(['hide', 'show'])

const props = defineProps<{
  show?: boolean
  size?: string
  title?: string
  parent?: any
  disabled?: boolean
  noBodyPadding?: boolean
}>()

const view = ref<HTMLElement | null>(null)
const viewBody = ref<HTMLElement | null>(null)
const isShowing = ref<boolean>(false)
const simpleBar = ref<SimpleBar | null>(null)
const offcanvas = ref<Offcanvas | null>(null)

const title = toRef(props, 'title')
const parent = toRef(props, 'parent')
const noBodyPadding = toRef(props, 'noBodyPadding')

const hide = () => {
  if (offcanvas.value) {
    offcanvas.value.hide()
  }
}

const show = () => {
  if (offcanvas.value) {
    offcanvas.value.show()
  }
}

const handleHide = () => {
  hide()
}

const scrollTop = () => {
  if (simpleBar.value) {
    const scrollElement = simpleBar.value.getScrollElement()
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

const scrollBottom = () => {
  if (simpleBar.value) {
    const scrollElement = simpleBar.value.getScrollElement()
    if (scrollElement) {
      scrollElement.scrollTo({ top: scrollElement.scrollHeight, behavior: 'smooth' })
    }
  }
}

defineExpose({
  show,
  hide,
  scrollTop,
  scrollBottom
})


onMounted(() => {
  if (view.value) {
    offcanvas.value = new Offcanvas(view.value)
    view.value.addEventListener('shown.bs.offcanvas', () => {
      isShowing.value = true
      emit('show')
    })
    view.value.addEventListener('hidden.bs.offcanvas', () => {
      isShowing.value = false
      emit('hide')
    })
  }
  if (viewBody.value) {
    simpleBar.value = new SimpleBar(viewBody.value)
  }
  if (props.show) {
    show()
  }
})

onBeforeUnmount(() => {
  if (offcanvas.value && isShowing.value) {
    hide()
  }
})
</script>

<template>
  <div ref="view" class="offcanvas offcanvas-end" :class="{'child': parent}" tabindex="-1"
       data-bs-backdrop="false" data-bs-keyboar="false" aria-labelledby="offcanvas-label">
    <div class="offcanvas-header" :class="{'border-bottom':title}">
      <h5 class="offcanvas-title pe-3">
        {{ title }}
      </h5>
      <button :disabled="disabled" @click="handleHide" type="button" class="btn-close text-reset"
              aria-label="Close"></button>
    </div>
    <div class="offcanvas-body overflow-hidden p-0">
      <div ref="viewBody" class="h-100 w-100" :class="{'p-3':!noBodyPadding}">
        <slot name="body" />
      </div>
    </div>
  </div>
  <div v-if="isShowing" class="offcanvas-backdrop fade show" :class="{'child': parent}"></div>
</template>