import {EntityModel} from "@/shared/model";
import {FileModel} from "@/shared/models/file_model";

export class ProjectModel extends EntityModel {
    public readonly title: string
    public readonly subtitle: string
    public readonly slug: string
    public readonly duration: string
    public readonly description: string
    public readonly clientName: string
    public readonly beforeImage: FileModel
    public readonly afterImage: FileModel

    constructor(data: object | null | undefined) {
        super(data)
        this.slug = this.getString('slug')
        this.title = this.getString('title')
        this.subtitle = this.getString('subtitle')
        this.duration = this.getString('duration')
        this.clientName = this.getString('client_name')
        this.description = this.getString('description')
        this.beforeImage = new FileModel(this.getObject('before_image'))
        this.afterImage = new FileModel(this.getObject('after_image'))
    }
}