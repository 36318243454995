import {Context} from "@/shared/context";
import {Config} from "@/shared/config";
import {Stores} from "@/shared/stores";
import {Logger} from "@/shared/logger";
import {Storage} from "@/shared/storage";
import {Helpers} from "@/shared/helpers";
import {Services} from "@/shared/services";
import {Messages} from "@/shared/messages";
import * as veeValidateRules from "@vee-validate/rules";
import {localize as veeValidateI18n} from "@vee-validate/i18n";
import {configure as veeValidateConfigure, defineRule as veeValidateDefineRule} from "vee-validate";
import moment from "moment-timezone";

class App {
    private isSetup: boolean = false

    public readonly config: Config
    public readonly stores: Stores
    public readonly logger: Logger
    public readonly storage: Storage
    public readonly helpers: Helpers
    public readonly services: Services
    public readonly messages: Messages

    constructor(ctx: Context) {
        this.config = ctx.config
        this.stores = ctx.stores
        this.logger = ctx.logger
        this.storage = ctx.storage
        this.helpers = ctx.helpers
        this.services = ctx.services
        this.messages = ctx.messages
    }

    public async setup(): Promise<boolean> {
        if (this.isSetup) {
            return true
        }
        type veeValidateRule = {
            [key: string]: any
        }
        const validationRules: veeValidateRule = veeValidateRules
        Object.keys(validationRules).forEach((rule: string) => {
            const ruleFn = validationRules[rule]
            if (ruleFn && typeof ruleFn === 'function') {
                veeValidateDefineRule(rule, ruleFn)
            }
        })
        veeValidateDefineRule('mask', (value: any, params: any): boolean => {
            if (params.length == 0) {
                return true
            }
            return moment(value, params[0], true).isValid()
        })
        veeValidateI18n('en', {
            messages: {
                email: '{field} in not a valid email address',
                required: '{field} is required',
            }
        })
        veeValidateConfigure({
            validateOnBlur: true,
            generateMessage: veeValidateI18n('en'),
            validateOnChange: true,
            validateOnModelUpdate: true
        })
        this.isSetup = true
        return true
    }
}

let app: App | null = null
export const useApp = (): App => {
    if (!app) {
        app = new App(new Context())
    }
    return app
}