import {Context} from "@/shared/context";

export class Logger {
    constructor(ctx: Context) {

    }

    public error(...data: any[]) {
        console.error(data)
    }
}